import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Legal Disclaimer | RWK Attorneys" defer={false} />


		<div className="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><Link to="/">Home</Link></li>
					<li class="breadcrumb-item"><Link to="/about">About</Link></li>
					<li class="breadcrumb-item active" aria-current="page">Legal Disclaimer</li>
				</ol>
			</nav>
			<section className="border rounded mt-5 p-3 col-6 text-justify">

				<h2>Legal Disclaimer</h2>
				<p><strong></strong>By using this site, you agree to the following Terms and Conditions of use. You must agree to these Terms and Conditions to use this site. If you do not agree to all of the Terms and Conditions of this agreement, you should exit the site now.</p>
				<p>THIS IS AN ADVERTISEMENT.</p>
				<p>The Alabama State Bar requires the following: “No representation is made that the quality of legal services to be performed is greater than the quality of legal services performed by other lawyers.”</p>
				<p>The Bankruptcy Code requires the following: “We are a debt relief agency.&nbsp; We help people file for bankruptcy under the Bankruptcy Code.”</p>
				<p>The case descriptions highlighted on this website are not an indication of future results or specific results which might occur in Your case, should we choose to accept Your case.&nbsp; Every case is different and must be evaluated on its own facts and circumstances and the applicable law.&nbsp; The outcome of a particular matter depends on many factors.</p>
				<p>This Site may make reference to or provide a link to other websites.&nbsp; Russo, White &amp; Keller, P.C. has no control over, or liability arising from information which may be found on other websites.</p>
				<p><span className="text-underline">No attorney-client relationship created by use of this site.</span> Use of this Site does not create, nor is it intended to create an attorney-client relationship.&nbsp; Contacting Russo, White &amp; Keller, P.C. by email, telephone, or other communication likewise does not establish, nor is it intended to establish, an attorney-client relationship.&nbsp; In order for an attorney-client relationship to exist, both a member of this Firm and You must sign a contract in writing.&nbsp; The Firm of Russo, White &amp; Keller, P.C. is a law firm with attorneys licensed only in the State of Alabama.</p>
			</section>

		</div>
	</Layout>
)